.clock-app-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.clock-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.clock-container {
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 250px;
}

.clock-text {
  font-size: 2rem;
  margin-bottom: 10px;
}

.time-zone-select, .speed-slider {
  margin-top: 10px;
  width: 80%;
  max-width: 200px;
}

.speed-display {
  font-size: 0.9rem;
  margin-top: 5px;
}

.add-clock-button, .remove-clock-button {
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin: 10px 0;
}

.remove-clock-button {
  background-color: #f44336;
}

.add-clock-button:hover, .remove-clock-button:hover {
  opacity: 0.8;
}

/* Digital Clock Styles */
.led-clock-container {
  background-color: #000;
  color: #00ff00;
}

.vintage-clock-container {
  background-color: #f4e4bc;
  color: #8b4513;
}

.modern-clock-container {
  background-color: #ffffff;
  color: #333333;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Analog Clock Styles */
.analog-clock {
  width: 200px;
  height: 200px;
  border: 10px solid #333;
  border-radius: 50%;
  position: relative;
  background: #f9f9f9;
}

.clock-face {
  position: relative;
  width: 100%;
  height: 100%;
}

.hand {
  width: 50%;
  height: 6px;
  background: #333;
  position: absolute;
  top: 50%;
  transform-origin: 100%;
  transform: rotate(90deg);
  transition: all 0.05s;
  transition-timing-function: cubic-bezier(0.1, 2.7, 0.58, 1);
}

.hour-hand {
  width: 30%;
  left: 20%;
}

.minute-hand {
  height: 4px;
  left: 10%;
}

.second-hand {
  height: 2px;
  background: #ff6b6b;
  left: 10%;
}

/* Binary Clock Styles */
.binary-clock {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.binary-row {
  display: flex;
  gap: 5px;
}

.binary-bit {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.binary-bit.on {
  background-color: #4CAF50;
}

.binary-bit.off {
  background-color: #ddd;
}

/* Error Styles */
.clock-container.error {
  border: 2px solid #ff4136;
  background-color: #ffdddd;
}

.error-message {
  color: #ff4136;
  font-size: 0.9rem;
  margin-top: 5px;
}

@media (max-width: 768px) {
  .clock-text {
    font-size: 1.5rem;
  }

  .time-zone-select, .speed-slider {
    width: 90%;
  }

  .analog-clock {
    width: 150px;
    height: 150px;
  }
}

@media (max-width: 480px) {
  .clock-app-container {
    padding: 10px;
  }

  .clock-grid-container {
    grid-template-columns: 1fr;
  }

  .clock-text {
    font-size: 1.2rem;
  }

  .add-clock-button, .remove-clock-button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }

  .analog-clock {
    width: 120px;
    height: 120px;
  }
}