.noise-level-checker {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
}

.button {
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.start-button {
  background-color: #4CAF50;
  color: white;
}

.stop-button {
  background-color: #f44336;
  color: white;
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.dark {
  background-color: #1a202c;
  color: #e2e8f0;
}

.light {
  background-color: #f7fafc;
  color: #2d3748;
}