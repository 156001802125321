.challenge-app {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

.header {
  text-align: center;
  margin-bottom: 1.5rem;
}

.header h1 {
  color: #4caf50;
  font-size: clamp(1.5rem, 5vw, 2.5rem);
  margin-bottom: 0.5rem;
  font-weight: 700;
}

.streak-counter {
  font-size: clamp(1rem, 3vw, 1.2rem);
  color: #f59e0b;
  font-weight: 600;
}

.category-filter {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
  margin-bottom: 1.5rem;
  padding: 0 0.5rem;
}

.category-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 1.5rem;
  background-color: #e0e0e0;
  color: #333;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: clamp(0.8rem, 2vw, 0.9rem);
  font-weight: 500;
  min-width: min(100px, 30vw);
}

.category-button.active {
  background-color: #4caf50;
  color: white;
}

.challenge-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 1fr));
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.challenge-card {
  height: clamp(200px, 50vw, 280px);
  perspective: 1000px;
  cursor: pointer;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.challenge-card:hover .card-inner,
.challenge-card:focus .card-inner {
  transform: rotateY(180deg);
}

.card-front, .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 1rem;
  padding: clamp(1rem, 3vw, 1.5rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.card-front {
  background-color: #f8f8f8;
}

.card-back {
  background-color: #4caf50;
  color: white;
  transform: rotateY(180deg);
}

.challenge-number {
  font-size: clamp(2rem, 6vw, 3rem);
  font-weight: bold;
  color: #4caf50;
}

.challenge-text {
  text-align: center;
  font-size: clamp(0.9rem, 2.5vw, 1.1rem);
  line-height: 1.4;
  margin-bottom: 1rem;
}

.action-button {
  width: min(140px, 80%);
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  font-size: clamp(0.8rem, 2vw, 0.9rem);
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: center;
}

.complete-button {
  background-color: white;
  color: #4caf50;
}

.complete-button:hover {
  background-color: #f0f0f0;
}

.progress-container {
  max-width: min(600px, 90%);
  margin: 0 auto;
  text-align: center;
}

.progress-bar {
  width: 100%;
  height: 12px;
  background-color: #e0e0e0;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 0.5rem;
}

.progress-fill {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.3s ease;
}

.progress-text {
  color: #666;
  font-weight: 500;
  margin-bottom: 1rem;
  font-size: clamp(0.9rem, 2.5vw, 1rem);
}

.share-button {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 0.75rem 1.5rem;
  font-size: clamp(0.9rem, 2.5vw, 1rem);
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.share-button:hover {
  background-color: #45a049;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Touch device optimizations */
@media (hover: none) {
  .challenge-card {
    cursor: default;
  }

  .challenge-card:active .card-inner {
    transform: rotateY(180deg);
  }
}

/* Media queries for smaller devices */
@media (max-width: 480px) {
  .challenge-app {
    padding: 0.5rem;
  }

  .challenge-grid {
    gap: 0.75rem;
  }

  .challenge-card {
    height: 180px;
  }

  .card-front, .card-back {
    padding: 0.75rem;
  }

  .action-button {
    padding: 0.4rem 0.8rem;
  }
}

/* Ensure touch targets are large enough */
@media (pointer: coarse) {
  .action-button {
    min-height: 44px;
  }

  .category-button {
    min-height: 44px;
  }
}