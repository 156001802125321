body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f0f4f8;
  color: #333;
  height: 100%;
  overflow-x: hidden;
}

#root {
  min-height: 100%;
  position: relative;
}

.lifetime-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 30px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
}

h1 {
  text-align: center;
  color: #2c3e50;
  margin-bottom: 30px;
  font-size: 2.5em;
}

.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 10px 15px;
  background-color: #f1f3f5;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  color: #495057;
  transition: background-color 0.3s;
}

.back-button:hover {
  background-color: #e9ecef;
}

.lifetime-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.lifetime-inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.input-group {
  flex: 1;
  min-width: 200px;
  display: flex;
  flex-direction: column;
}

.input-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

.input-group input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.lifetime-activities {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.activity-slider {
  display: flex;
  align-items: center;
  gap: 15px;
}

.activity-slider label {
  width: 100px;
  font-weight: bold;
}

.activity-slider input[type="range"] {
  flex-grow: 1;
}

.activity-slider span {
  width: 70px;
  text-align: right;
}

.calculate-button {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.calculate-button:hover {
  background-color: #45a049;
}

.results-card {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 30px;
}

.results-card h2 {
  color: #2c3e50;
  margin-bottom: 20px;
  font-size: 1.8em;
}

.time-bar {
  height: 30px;
  background-color: #e9ecef;
  border-radius: 15px;
  overflow: hidden;
  margin: 20px 0;
}

.time-remaining {
  height: 100%;
  background-color: #4CAF50;
  transition: width 0.3s ease;
}

.time-breakdown {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.time-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.time-value {
  font-size: 3em;
  font-weight: bold;
  color: #4CAF50;
}

.time-label {
  font-size: 1.2em;
  color: #6c757d;
}

.share-button {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 30px;
}

.share-button:hover {
  background-color: #2980b9;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background: #ddd;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

input[type="range"]:hover {
  opacity: 1;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #4CAF50;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #4CAF50;
  cursor: pointer;
}

canvas {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 1000;
  pointer-events: none;
}

.share-card {
  position: absolute;
  left: -9999px;
  top: -9999px;
  width: 600px;
  height: 315px;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: 'Arial', sans-serif;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.share-card-content {
  text-align: center;
  padding: 20px;
}

.share-card h2 {
  font-size: 2em;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
}

.share-card-message {
  font-size: 1.2em;
  margin: 10px 0;
  opacity: 0.9;
}

.share-card-days {
  font-size: 4em;
  font-weight: bold;
  margin: 20px 0;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
}

@media (max-width: 600px) {
  .lifetime-container {
    margin: 20px auto;
    padding: 20px;
  }

  h1 {
    font-size: 2em;
  }

  .input-group {
    min-width: 100%;
  }

  .activity-slider {
    flex-direction: column;
    align-items: flex-start;
  }

  .activity-slider label {
    width: 100%;
    margin-bottom: 5px;
  }

  .activity-slider span {
    width: 100%;
    text-align: left;
    margin-top: 5px;
  }

  .time-value {
    font-size: 2.5em;
  }

  .time-label {
    font-size: 1em;
  }

  .share-card {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
  }

  .share-card h2 {
    font-size: 1.5em;
  }

  .share-card-message {
    font-size: 1em;
  }

  .share-card-days {
    font-size: 3em;
  }
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  width: 90%;
}

.close-modal {
  margin-top: 15px;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.close-modal:hover {
  background-color: #e0e0e0;
}

.error-message {
  color: red;
  margin-bottom: 10px;
  font-weight: bold;
}

.share-prompt {
  text-align: left;
}

.share-prompt ol {
  margin-bottom: 20px;
}

.share-image {
  max-width: 100%;
  margin-bottom: 20px;
}

.social-share-buttons {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.social-share-buttons button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.social-share-buttons button:nth-child(1) {
  background-color: #1DA1F2;
  color: white;
}

.social-share-buttons button:nth-child(2) {
  background-color: #4267B2;
  color: white;
}

.social-share-buttons button:nth-child(3) {
  background-color: #25D366;
  color: white;
}

.pie-chart-container {
  margin-top: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.pie-chart-container h3 {
  margin-bottom: 15px;
  text-align: center;
  color: #333;
}

.recharts-wrapper {
  margin: 0 auto;
}

.recharts-default-tooltip {
  background-color: rgba(255, 255, 255, 0.9) !important;
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.recharts-tooltip-label {
  font-weight: bold !important;
  margin-bottom: 5px !important;
}

.recharts-tooltip-item {
  padding: 2px 0 !important;
}

.recharts-legend-item {
  cursor: pointer !important;
}

.recharts-legend-item-text {
  font-size: 14px !important;
}

@media (max-width: 600px) {
  .pie-chart-container {
    padding: 10px;
  }

  .recharts-wrapper {
    font-size: 12px;
  }

  .recharts-legend-item-text {
    font-size: 12px !important;
  }
}

.remove-activity-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 14px;
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-activity-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.new-activity-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 16px;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.modal-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.add-button {
  background-color: #4CAF50;
  color: white;
}

.cancel-button {
  background-color: #f44336;
  color: white;
}


.lifetime-activities {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.activity-item {
  display: flex;
  align-items: center;
  gap: 15px;
  background-color: #f8f9fa;
  padding: 10px 15px;
  border-radius: 5px;
}

.activity-name-input {
   width: 120px; /* Set a fixed width */
  min-width: 120px; /* Ensure it doesn't shrink below this width */
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 16px;
}

.activity-controls {
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 250px;
}

.activity-controls input[type="range"] {
  flex-grow: 1;
}

.activity-hours {
  min-width: 70px;
  text-align: right;
}

.remove-activity-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
   flex-shrink: 0; /* Prevent the button from shrinking */
  transition: background-color 0.3s;
}

.remove-activity-button:hover {
  background-color: #ff3333;
}

.add-activity-button {
  align-self: flex-start;
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.add-activity-button:hover {
  background-color: #45a049;
}

@media (max-width: 600px) {
  .activity-item {
    flex-direction: column;
    align-items: stretch;
  }

  .activity-name-input {
    margin-bottom: 10px;
  }

  .activity-controls {
    min-width: auto;
  }

  .activity-hours {
    min-width: 50px;
  }
}