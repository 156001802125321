.graph-matrix-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Adjust columns as needed */
  gap: 20px; /* Space between cards */
  padding: 20px;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

.graph-matrix-flip-card {
  perspective: 1000px;
  width: 300px;
  height: 300px;
}

.graph-matrix-flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.8s;
}

.graph-matrix-flip-card:hover .graph-matrix-flip-card-inner {
  transform: rotateY(180deg);
}

.graph-matrix-flip-card-front,
.graph-matrix-flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.graph-matrix-flip-card-front {
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
}

.graph-matrix-flip-card-back {
  background-color: #f9f9f9;
  color: black;
  transform: rotateY(180deg);
  padding: 10px;
}

.graph-container {
  width: 100%;
  height: 100%;
}