/* DreamsBeginHere.css */
:root {
  --primary-color: #9333ea;
  --primary-hover: #7e22ce;
  --secondary-color: #3b82f6;
  --secondary-hover: #2563eb;
  --success-color: #10b981;
  --warning-color: #f59e0b;
  --error-color: #ef4444;
  --background-gradient: linear-gradient(135deg, #f3f4f6 0%, #ede9fe 100%);
  --card-gradient: linear-gradient(135deg, #eef2ff 0%, #faf5ff 100%);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  line-height: 1.5;
}

/* Container styles */
.dreams-container {
  min-height: 100vh;
  background: var(--background-gradient);
  padding: 2rem 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

/* Header styles */
.main-header {
  text-align: center;
  margin-bottom: 2.5rem;
  padding: 2rem 1rem;
}

.main-title {
  font-size: 3rem;
  font-weight: bold;
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: 0.75rem;
}

.main-subtitle {
  color: #6b7280;
  font-size: 1.25rem;
}

/* Form styles */
.form-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 1rem;
}

.dreams-form {
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.form-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 2rem;
  text-align: center;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
  font-size: 1rem;
  transition: all 0.2s ease;
  background-color: white;
}

.form-group select {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(147, 51, 234, 0.1);
}

.form-group select option {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.5rem;
}

.submit-button {
  width: 100%;
  padding: 1rem;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-size: 1.125rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.submit-button:hover {
  background-color: var(--primary-hover);
  transform: translateY(-1px);
}

.submit-button:active {
  transform: translateY(0);
}

/* Boarding Pass styles */
.boarding-pass {
  max-width: 800px;
  margin: 0 auto 2rem;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.boarding-pass-header {
  background: var(--card-gradient);
  padding: 1.5rem;
  text-align: center;
  border-bottom: 1px solid #e5e7eb;
}

.boarding-pass-header h2 {
  font-size: 1.875rem;
  color: var(--primary-color);
  margin: 0;
}

.boarding-pass-content {
  background: white;
}

.boarding-pass-inner {
  padding: 2rem;
}

.flight-details-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  max-width: 100%;
}

.flight-info-column {
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.info-item {
  min-width: 0;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.info-label {
  font-size: 0.875rem;
  color: #6b7280;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-bottom: 0.25rem;
}

.info-value {
  font-size: 1.25rem;
  font-weight: 500;
  color: #111827;
  word-break: break-word;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.boarding-pass-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1.5rem;
  background: #f9fafb;
  border-top: 1px solid #e5e7eb;
}

.action-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.share-button {
  background-color: var(--secondary-color);
  color: white;
}

.share-button:hover {
  background-color: var(--secondary-hover);
  transform: translateY(-1px);
}

.download-button {
  background-color: var(--primary-color);
  color: white;
}

.download-button:hover {
  background-color: var(--primary-hover);
  transform: translateY(-1px);
}

.action-button:active {
  transform: scale(0.98);
}

/* Journey Planner styles */
.journey-planner {
  max-width: 800px;
  margin: 0 auto 2rem;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.journey-planner-header {
  background: var(--card-gradient);
  padding: 1.5rem;
  border-bottom: 1px solid #e5e7eb;
}

.journey-title {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--primary-color);
}

.journey-content {
  padding: 2rem;
}

.journey-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.destination-info,
.countdown-info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.destination-text,
.countdown-text {
  font-size: 1.25rem;
  font-weight: 500;
}

.planning-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-bottom: 2rem;
}

.notes-section,
.milestones-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.notes-section h3,
.milestones-section h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #374151;
}

.notes-textarea {
  width: 100%;
  min-height: 150px;
  padding: 1rem;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
  font-size: 1rem;
  resize: vertical;
  transition: all 0.2s ease;
}

.notes-textarea:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(147, 51, 234, 0.1);
}

.milestone-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.milestone-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  transition: background-color 0.2s ease;
}

.milestone-item:hover {
  background-color: #f9fafb;
}

.milestone-checkbox {
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid #d1d5db;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.milestone-checkbox:checked {
  background-color: var(--success-color);
  border-color: var(--success-color);
}

.milestone-text {
  font-size: 1rem;
  color: #374151;
}

.milestone-text.completed {
  text-decoration: line-through;
  color: #9ca3af;
}

.add-milestone-form {
  display: flex;
  gap: 0.75rem;
  margin-top: 1rem;
}

.milestone-input {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
  font-size: 1rem;
  transition: all 0.2s ease;
}

.milestone-input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(147, 51, 234, 0.1);
}

.add-milestone-button {
  padding: 0.75rem 1.5rem;
  background-color: var(--secondary-color);
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.add-milestone-button:hover {
  background-color: var(--secondary-hover);
  transform: translateY(-1px);
}

.add-milestone-button:active {
  transform: translateY(0);
}

.affirmation-box {
  background: var(--card-gradient);
  padding: 2rem;
  border-radius: 0.5rem;
  text-align: center;
  margin-top: 2rem;
}

.affirmation-box h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #374151;
  margin-bottom: 1rem;
}

.affirmation-text {
  font-size: 1.25rem;
  font-style: italic;
  color: var(--primary-color);
  line-height: 1.75;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: #f3f4f6;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: #374151;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 1rem;
}

.back-button:hover {
  background-color: #e5e7eb;
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.boarding-pass,
.journey-planner {
  animation: fadeIn 0.5s ease-out;
}

/* Responsive styles */
@media (max-width: 768px) {
  .dreams-container {
    padding: 1rem;
  }

  .main-title {
    font-size: 2rem;
  }

  .form-grid,
  .flight-details-grid,
  .planning-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .journey-summary {
    flex-direction: column;
    align-items: flex-start;
  }

  .boarding-pass-actions {
    flex-direction: column;
  }

  .action-button {
    width: 100%;
    justify-content: center;
  }

  .boarding-pass,
  .journey-planner {
    margin: 1rem;
  }

  .boarding-pass-inner {
    padding: 1rem;
  }

  .info-value {
    font-size: 1rem;
  }

  .journey-content {
    padding: 1rem;
  }
}

/* Print styles */
@media print {
  .dreams-container {
    padding: 0;
    background: none;
  }

  .boarding-pass-actions,
  .back-button {
    display: none;
  }



.boarding-pass,
.journey-planner {
  animation: fadeIn 0.5s ease-out;
}

}