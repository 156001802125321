.believe-wrapper {
  background-color: #0057b7; /* Ted Lasso blue */
  font-family: 'Arial', sans-serif;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.believe-header {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  color: #ffcc00; /* Ted Lasso yellow */
  padding: 10px 0;
}

.believe-header h1 {
  margin: 0;
  font-size: 36px;
}

.streak-info {
  display: flex;
  align-items: center;
}

.streak-info span {
  margin-right: 10px;
  font-size: 18px;
}

.streak-info button {
  background-color: #ffcc00;
  color: #0057b7;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.streak-info button:hover {
  background-color: #ffd633;
}

.believe-matrix {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  max-width: 1200px;
  width: 100%;
}

.believe-cell {
  aspect-ratio: 1 / 1;
  perspective: 1000px;
}

.believe-content {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.believe-front, .believe-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
}

.believe-front {
  background-color: #ffcc00; /* Ted Lasso yellow */
  font-size: 24px;
  color: #0057b7;
  font-weight: bold;
}

.believe-back {
  background-color: #ffcc00; /* Ted Lasso yellow */
  font-size: 16px;
  color: #0057b7;
  text-align: center;
  transform: rotateY(180deg);
}

.believe-swivel {
  transform: rotateY(180deg);
}

@media (max-width: 1200px) {
  .believe-matrix {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 900px) {
  .believe-matrix {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 600px) {
  .believe-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .streak-info {
    margin-top: 10px;
  }

  .believe-matrix {
    grid-template-columns: repeat(2, 1fr);
  }

  .believe-front {
    font-size: 20px;
  }

  .believe-back {
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  .believe-matrix {
    grid-template-columns: 1fr;
  }

  .believe-header h1 {
    font-size: 28px;
  }

  .streak-info span {
    font-size: 16px;
  }

  .streak-info button {
    font-size: 14px;
    padding: 8px 12px;
  }
}